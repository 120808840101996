import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/layout/Layout";
import SEO from "../components/seo";

interface Props {}

const KontaktyPage = (props: Props) => {
  return (
    <Layout>
      <SEO title="Kontakty" />
      <h1>Kontakty</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 mb-5">
      <div>
        <div>
          ETKA labels, s.r.o.
          <br />
          Lojovice 76
          <br />
          251 69 Velké Popovice
          <br />
          ČR
        </div>
        <div className="my-3">
          Provozní doba: Po - Pá 7 - 16, So - Ne Zavřeno
        </div>
        <div className="my-3">
          Tel.:{" "}
          <a className="text-etka-blue font-bold" href="tel:+420323619060">
            +420 323 619 060
          </a>
          <br />
          Email:{" "}
          <a className="text-etka-blue font-bold" href="mailto:office@etka.cz">
            office@etka.cz
          </a>
        </div>

      </div>
      <div>
      <StaticImage
          src="../images/dum.jpg"
          alt="Etka"
        />
      </div>
      
      </div>
      
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2570.074353599906!2d14.647607515711432!3d49.89740737940293!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b8634fc42dc87%3A0x4994f6b265368216!2sLojovice%2076%2C%20251%2069%20Velk%C3%A9%20Popovice!5e0!3m2!1sen!2scz!4v1644237391288!5m2!1sen!2scz"
        width="100%"
        height="450"
        loading="lazy"
      ></iframe>
    </Layout>
  );
};

export default KontaktyPage;
